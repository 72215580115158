import React, { useEffect, useMemo, useRef } from 'react'
import { Alert, AppBar, Box, Button, CircularProgress, DialogContent, DialogTitle, Divider, Drawer, Menu, MenuItem, Snackbar, TextField, Toolbar, Tooltip, Typography  } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import eezibLogo from "../images/eezib.png"
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import GavelIcon from '@mui/icons-material/Gavel';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { HandleApi } from '../HandleApi';
import Footer from '../pages/Footer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WidgetsIcon from '@mui/icons-material/Widgets';
import Zoom from '@mui/material/Zoom';
import PersonIcon from '@mui/icons-material/Person';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import eezibWhite from "../images/eezib2.png";
import profileCard from "../images/profile.png"
import PasswordIcon from '@mui/icons-material/Password';
import passwordImage from "../images/password.jpg";
import moment from 'moment';
import walletBalanceIcon from "../images/wallet_balance.png";
import walletLogo from "../images/walletLogo.png";
import VisibilityIcon from '@mui/icons-material/Visibility';
import eeLogo from "../images/ee.jpg";
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import creditCardLogo from "../images/creditCardLogo.png";
import voucherIcon from "../images/giftVoucherIcon.png";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import confetti from 'canvas-confetti';
import html2canvas from 'html2canvas';
import upi from "../images/UPI.webp";
import bhim from "../images/BHIM.webp"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const Header = (prop) => {

  
  const [ action , setAction ] = React.useState([]);
  const [ cat , setCat ] = React.useState('');
  const [activateOpen , setActiveOpen] = React.useState(false);
  const [activateSOpen , setActiveSOpen] = React.useState(false);
  const [jsonError , setJsonError] = React.useState('');
  const [ jsonSError , setJsonSError ] = React.useState('');

  const [ openDrawer, setOpenDrawer ] = React.useState(false);

  const [anchorElVoucher, setAnchorElVoucher] = React.useState(null);
  const [ anchorElName , setAnchorELName ] = React.useState(null);
  const [ anchorElBal , setAnchorELBal ] = React.useState(null);
  const openBal = Boolean(anchorElBal);

  const [ walletBalance , setWalletBalance ] = React.useState("");
  const [ transcorpBalance , setTranscopBalance ] = React.useState("");
  const [ finoBalance , setFinoBalance ] = React.useState("");

  const [openProfile, setOpenProfile] = React.useState(false);

  const [userName , setUserName ] = React.useState('');
  const [phoneNumber , setPhoneNumber ] = React.useState('');
  const [ userEmail , setUserEmail ] = React.useState('');
  const [ userDob , setUserDob ] = React.useState(null);
  const [ userAddress , setUserAddress ] = React.useState(""); 

  const [ openPassword , setOpenPassword ] = React.useState(false);

  const [ oldPass , setOldPass ] = React.useState('');
  const [ newPass , setNewPass ] = React.useState('');
  const [ profileProgress , setProfileProgress ] = React.useState(false);

  const [ walletProg , setWalletProg ] = React.useState(false);
  const [ walletCount , setWalletCount ] = React.useState(0);
  const [ transProg , setTransProg ] = React.useState(false);
  const [ finoProg , setFinoProg ] = React.useState(false);

  const [openQrModal , setOpenQrModel ] = React.useState(false);

  const [init, setInit] = React.useState(false);
  const [ openTopup, setOpenTopup ] = React.useState(false);

  const handleTopup = () => {
    setOpenTopup(false);
  }


  const contentRef = useRef(null); 
  const QRModel = sessionStorage.getItem("userQr");


  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
  };

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: "#0d47a1",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    [],
  );


  const handleOpenQrModal = () => {
    // Start the initial confetti burst
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      zIndex: 9999,
    });

    // *******************************************

    // josham josh

  //   const count = 200,
  //   defaults = {
  //     origin: { y: 0.7 },
  //   };
  
  // function fire(particleRatio, opts) {
  //   confetti(
  //     Object.assign({}, defaults, opts, {
  //       particleCount: Math.floor(count * particleRatio),
  //     })
  //   );
  // }
  
  // fire(0.25, {
  //   spread: 26,
  //   startVelocity: 55,
  //   zIndex: 9999,
  // });
  
  // fire(0.2, {
  //   spread: 60,
  //   zIndex: 9999,
  // });
  
  // fire(0.35, {
  //   spread: 100,
  //   decay: 0.91,
  //   scalar: 0.8,
  //   zIndex: 9999,
  // });
  
  // fire(0.1, {
  //   spread: 120,
  //   startVelocity: 25,
  //   decay: 0.92,
  //   scalar: 1.2,
  //   zIndex: 9999,
  // });
  
  // fire(0.1, {
  //   spread: 120,
  //   startVelocity: 45,
  //   zIndex: 9999,
  // });

    // ************************************************************

    // side pettals
  
    // Define the duration for the continuous confetti animation
    // const end = Date.now() + 1 * 1000; // 15 seconds
    // const colors = ["#57bcff", "#ffffff"];
  
    // // Define the continuous confetti animation
    // (function frame() {
    //   confetti({
    //     particleCount: 2,
    //     angle: 60,
    //     spread: 55,
    //     origin: { x: 0 },
    //     colors: colors,
    //     zIndex: 9999,
    //   });
  
    //   confetti({
    //     particleCount: 2,
    //     angle: 120,
    //     spread: 55,
    //     origin: { x: 1 },
    //     colors: colors,
    //     zIndex: 9999,
    //   });
  
    //   if (Date.now() < end) {
    //     requestAnimationFrame(frame);
    //   }
    // })();
  
    // Open the QR modal
    setOpenQrModel(true);
  };

  const handleQrModel = () => {
    setOpenQrModel(false);
  }


  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const handlePasswordClose = () => {
    setOpenPassword(false);
  }

  const handleProfileOpen = () => {
    setOpenProfile(true);
    userProfile()
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

    const openVoucher = Boolean(anchorElVoucher);
    const openLogout = Boolean(anchorElName);
    

    const handleVoucherClick = (event) => {
      setAnchorElVoucher(event.currentTarget)
    }

    const handleNameClick = (event) => {
      setAnchorELName(event.currentTarget)
    }

    const handleVoucherClose = () => {
      setAnchorElVoucher(null);
    };

    const handleNameClose = () => {
      setAnchorELName(null);
    }

    const handleBalAnc = async(event) => {
      try{

        setWalletProg(true)
  
    // setOpenDrawer(true);      
  
        // setAnchorELBal(event.currentTarget);
  
        const url = process.env.REACT_APP_UAPI_URL;
        const apiUrl = `${url}/api/b2cUserWallet`;
        const method = "GET";
    
        const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });
    
  
        setWalletProg(false)
  
        if(json){
          if(json.status === "success"){
            setWalletBalance(json.data.walletbalance);
  
        setTimeout(() => {
          setWalletBalance("");
        },60000)
          }
          else if(json.status === "error"){
            setActiveOpen(true);
            setJsonError(json.message);
          }
        }
      }catch(err){

        setActiveOpen(true);
        setJsonError(err);

      }


    }

    const handleTranscorpBlnc = async() => {
      try{
        setTransProg(true);
  
        const url = process.env.REACT_APP_UAPI_URL;
        const apiUrl = `${url}/api/fetchTranscorpBalance`;
        const method = "POST";
        const body =  {
          "id":"4"
      }
        const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, bodyData:body });
  
        setTransProg(false);
  
        if(json){
          if(json.status === "success" && json.statuscode === 200 ){
            setTranscopBalance(json?.data?.amount)
  
            setTimeout(() => {
              setTranscopBalance("");
            },60000)
  
          }
          else if(json.status === "error"){
            setActiveOpen(true);
            setJsonError(json.message);
          }
        }

      }catch(err){
        setActiveOpen(true);
        setJsonError(err);
      }

    }

    const handleFinoBlnc = async() => {

      try{

        setFinoProg(true);
  
        const url = process.env.REACT_APP_UAPI_URL;
        const apiUrl = `${url}/api/fetchFinoBalance`;
        const method = "POST";
        const body =  {
          "id":"3"
      }
        const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, bodyData:body });
  
        setFinoProg(false);
  
        if(json){
          if(json.status === "success" && json.statuscode === 200 ){
            setFinoBalance(json?.data?.amount)
  
            setTimeout(() => {
              setFinoBalance("");
            },60000)
  
          }
          else if(json.status === "error"){
            setActiveOpen(true);
            setJsonError(json.message);
          }
        }
      }catch(err){
          setActiveOpen(true);
          setJsonError(err);
      }

    }


    const handleBalClose = () => {
      setAnchorELBal(false)
    }


  const navigate = useNavigate();

  const handleActiveCardClose = () => {
    setActiveOpen(false);
}

const handleActiveSCardClose = () => {
  setActiveSOpen(false);
}


 const [darkMode , setdarkMode ] = React.useState(false);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openOrder = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openName = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const removeCookies = () => {
    Cookies.remove("demoCAuth");
    Cookies.remove("userName");
    Cookies.remove("tcValue");
    Cookies.remove("demoAuth");
  }

  const handledarkMode = () => {
    Cookies.set("darkMode",true);
    setdarkMode(true);
  }

  const name = Cookies.get("userName");
  console.log("cat : ",cat);

  const category = Cookies.get("tcValue");

  const handleSpeedDial = () => {  

    if(category === "transcorp"){
      setAction([
        { icon: <GavelIcon/>, name:"Terms & Conditions (English)", path:"https://b2c.eezib.in/tcEnglish" },
        { icon: <ReceiptLongIcon/>, name:"Terms & Conditions (Hindi)", path:"https://b2c.eezib.in/tcHindi" },
        { icon: <SupportAgentIcon/>, name:"Transcorp Support - cards@transcorpint.com", mail:"cards@transcorpint.com" },
        { icon: <PhoneInTalkIcon/>, name:"Contact - 7597182222", value:"7597182222"  },

      ])
    }

    if(category === "fino"){
      setAction([
        { icon: <GavelIcon/>, name:"Terms and Conditions Fino", path:"https://b2cuat.eezib.in/TnC/Fino/FinoTnC.pdf" },
        { icon: <ReceiptLongIcon/>, name:"Frequently Asked Questions Fino", path:"https://web4.finobank.com/faqs" },
        { icon: <SupportAgentIcon/>, name:"Fino Support - customercare@finobank.com", mail:"customercare@finobank.com" },
        { icon: <PhoneInTalkIcon/>, name:"Contact - 022-68681414", value:"022-68681414" },

        // {icon : <SupportAgentIcon/>, name:"Support", path:"customercare@fi8nobank.com" }
      ])
    }

    if(category === "eezib"){
      setAction([
        { icon: <SupportAgentIcon/>, name:"Eezib Support - support@eezib.com ", mail:"support@eezib.com" },
        { icon: <PhoneInTalkIcon/>, name:"Contact - 97820 09070", value:"9782009070" },

          ])
    }
    

  }

  React.useEffect(() => {
    handleSpeedDial()
  },[category])


  // const actions = [
  //   { icon: <GavelIcon />, name: 'Term & Condition', path:"https://b2c.eezib.in/tcEnglish" },
  //   { icon: <SupportAgentIcon />, name: 'support@eezib.com' , path:"mailto:support@eezib.com" },
  //   { icon: <ReceiptLongIcon />, name: 'नियम और शर्तें ', path:"https://b2c.eezib.in/tcHindi" },
  // ];

  const authAccess = Cookies.get("demoCAuth");
  const QR = Cookies.get("demoCQr");
  const messageQr = sessionStorage.getItem("messageQr");

  const logout = async() => {
    // const fetchData = await fetch(`${url}/api/logout`, {
    //   method:"POST",
    //   headers:{
    //     "Accept":"application/json",
    //     "Content-Type":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({})
    // });
    // const json = await fetchData.json();


    const url = process.env.REACT_APP_UAPI_URL;


    const apiUrl = `${url}/api/logout`;
    const method = "POST";

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":"", "authAccess":authAccess });


    if(json){
      if(json.status === "success" && json.statuscode === 200 ){
        removeCookies();
        setActiveSOpen(true)
        setJsonSError(json.message);
        sessionStorage.clear();
        setTimeout(() => {
            window.location.replace(`${process.env.REACT_APP_UAPI_URL}/logout`);
          },1200)
      }

      if(json.message === "Unauthenticated."){
        setActiveOpen(true);
        setJsonError(json.messsge);
        removeCookies();

        setTimeout(() => {
          window.location.replace(`${process.env.REACT_APP_UAPI_URL}/logout`);
        },1200)
      }

      if(json.status === "error"){
        setActiveOpen(true);
        setJsonError(json.message);

        removeCookies();

        setTimeout(() => {
          window.location.replace(`${process.env.REACT_APP_UAPI_URL}/logout`);
        },1200)
      }

    }
  }

  const 
  handleRedirection = async () => {

    // redirection path 'http://divanshu.local:3001/eezib?home='

    const url = process.env.REACT_APP_UAPI_URL;
    const apiUrl = `${url}/api/generate_token`;
    const method = "GET";

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });



    if(json){
      if(json.status === "success"){

        // window.alert(json.data)

        Cookies.remove("demoCAuth");
        Cookies.set("demoCAuth",json.data);

        // window.open(`${process.env.REACT_APP_UAPI_URL}/eezib?home=${json.data}`, ""); 

       window.open(`http://192.168.0.133:3001/eezib?home=${json.data}`,"");

        // window.alert(json.data)
        window.location.reload();
      }
      if(json.status === "error"){
        setJsonError(json.message);
        setActiveOpen(true);
      }
    }
  }

    const userProfile = async() => {

      setProfileProgress(true);

    const url = process.env.REACT_APP_UAPI_URL;

      // https://b2cuat.eezib.in/api/profile

    const apiUrl = `${url}/api/profile`;
    const method = "GET";

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });


    setProfileProgress(false);

    if(json.statuscode === 200 ){

      setUserName(json.data.name);
      setPhoneNumber(json.data.phone_no);
      setUserEmail(json.data.email);
      setUserDob(json.data.dob);
      setUserAddress(json.data.address);

    }

    if(json.status === "error "){
      setJsonError(json.message);
      setActiveOpen(true);
    }

    }

    
    const submitUpdate = async() =>{
      
    const url = process.env.REACT_APP_UAPI_URL;
    const apiUrl = `${url}/api/update_profile`;
    const method = "POST";
    const bodyData = {
      "name": userName,
      "email": userEmail,
      "dob": userDob ,
      "address": userAddress
      }
      
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, bodyData:bodyData  });
      

      if(json){
        if(json.statuscode === 200 ){
          setJsonSError(json.message);
          setActiveSOpen(true);
        }

        if(json.status === "error" ){
          setJsonError(json.message);
          setActiveOpen(true);

          
        }
      }
      
      }


        // React.useEffect(() => {
        //   userProfile()
        // },[])

        const openUpdatePass = () => {
          setAnchorELName(null);
          setOpenPassword(true);
        }


        const updatePassword = async() => {
         // https://b2cuat.eezib.in/api/resetPassword

         const url = process.env.REACT_APP_UAPI_URL;
         const apiUrl = `${url}/api/resetPassword`;
         const method = "POST";
         const bodyData = {
          "oldPassword":oldPass,
          "newPassword":newPass
      
           }
           
           const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, bodyData:bodyData  });

          if(json){
            if(json.statuscode === 200 ){
              setJsonSError(json.message);
              setActiveSOpen(true);

              setOldPass("");
              setNewPass("");

              setTimeout(() => {
                setOpenPassword(false);
              },1000)
            }
    
            if(json.status === "error" ){
              setJsonError(json.message);
              setActiveOpen(true);
              
            }
          }

        }



        const DrawerList = (
          <Box sx={{ width:350 }} role="presentation" >
            <ChevronLeftIcon onClick={() => setOpenDrawer(false)} sx={{ position:'absolute', right:0, mt:1, fontSize:"1.5rem", cursor:'pointer' }} />
         


          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:1 }} >
            <Box component='img' src={eezibLogo} sx={{ width:"5rem", textAlign:'center' }} />
          </Box>

            <Box sx={{ mt:3 }} >
          <Typography sx={{  textAlign:'center', mt:1, fontFamily:'Poppins', fontSize:"1rem" }} >Welcome <span style={{ textTransform:'capitalize', color:"#3871eb" }} > {name} </span> </Typography>
            </Box>

              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:1, flexDirection:'column' }} >
            <div onClick={handleOpenQrModal} style={{ width:"9rem", mt:1, cursor:'pointer' }} dangerouslySetInnerHTML={{ __html: QRModel }} />
            <Typography sx={{ fontFamily:'Poppins', fontWeight:400, fontSize:"0.75rem", color:"gray", p:0.5 }} >{messageQr}</Typography>
              </Box>


          <Box sx={{ mt:2 }} >
              <List>
                  <Box sx={{  padding:1.5, mb:0.6 }} >
                <ListItem disablePadding>

                    <ListItemText  >
                      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly' }} >


                      <Box sx={{  display:'flex', alignItems:'center', flexDirection:'column' }} >
                    
                      <ListItemIcon sx={{ mt:0.5, mb:0.5  }} >
                      <Box component='img' src={ eeLogo } sx={{ width:"3rem" }} />
                    </ListItemIcon>



                    <ListItemIcon sx={{ mt:0.5, mb:0.5  }} >
                    <Box component='img' src={ eeLogo } sx={{ width:"3rem" }} />
                    </ListItemIcon>

                    <ListItemIcon sx={{ mt:0.5, mb:0.5  }} >
                    <Box component='img' src={ eeLogo } sx={{ width:"3rem" }} />
                    </ListItemIcon>

                    </Box>
                        

                    <Box sx={{ display:'flex', alignItems:'center', flexDirection:'column' }} >
                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400, width:"10.1rem", mt:2, mb:2 }} >
                      Wallet Balance :
                    </Typography>  

                    {/* <Divider variant='middle' sx={{ width:"100%" }} /> */}

                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400, width:"10.1rem", mt:2, mb:2 }} >
                      Transcorp Balance :
                    </Typography>

                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400, width:"10.1rem", mt:2, mb:2 }} >
                      Fino Balance :
                    </Typography>

                    </Box>

                    <Box sx={{  display:'flex', alignItems:'center', justifyContent:"right" ,flexDirection:'column', width:"7rem" }} >
                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400, mt:2, mb:2, textAlign:'right' }} >
                        { walletProg ? <CircularProgress size={20} /> :   walletBalance ? walletBalance : "****" }
                    </Typography>  

                    {/* <Divider variant='middle' sx={{ width:"100%" }} /> */}

                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400, mt:2, mb:2, textAlign:'right' }} >
                        { transProg ? <CircularProgress size={20} /> : transcorpBalance ? transcorpBalance : "****" }
                    </Typography>

                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400, mt:2, mb:2, textAlign:'right' }} >
                        { finoProg ? <CircularProgress size={20} /> : finoBalance ? finoBalance : "****" }
                    </Typography>

                        </Box>

                        <Box sx={{  display:'flex', alignItems:'center', flexDirection:'column',  }} >
                          <VisibilityIcon onClick={handleBalAnc} sx={{ fontSize:"1.2rem", cursor:'pointer', mt:2.2, mb:2.2  }} />
                          <VisibilityIcon onClick={handleTranscorpBlnc} sx={{ fontSize:"1.2rem", cursor:'pointer', mt:2.2, mb:2.2  }} />
                          <VisibilityIcon onClick={handleFinoBlnc} sx={{ fontSize:"1.2rem", cursor:'pointer', mt:2.2, mb:2.2  }} />
                        </Box>
                        
{/* 
                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400 }} >
                      { walletProg ? <CircularProgress/> :   walletBalance ? walletBalance : "****" }
                    </Typography>
                    <VisibilityIcon onClick={handleBalAnc} sx={{ fontSize:"1.2rem", cursor:'pointer' }} />
                      </Box>
                    </ListItemText>
                </ListItem>
                  </Box>

                  <Divider variant='middle' />

                  {/* <Box sx={{  padding:1, mb:0.6 }} >
                <ListItem disablePadding>
                    <ListItemIcon>
                    <Box component='img' src={ creditCardLogo } sx={{ width:"2.5rem" }} />
                    </ListItemIcon>
                    <ListItemText  >
                      
                      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400 }}>
                      Transcorp Balance :
                    </Typography>

                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400 }}>
                     { transProg ? <CircularProgress/> : transcorpBalance ? transcorpBalance : "****" }
                    </Typography>

                    <VisibilityIcon onClick={handleTranscorpBlnc} sx={{ fontSize:"1.2rem", cursor:'pointer' }} />
                    
                      </Box>
                    </ListItemText>
                </ListItem>
                  </Box>

                  <Divider variant='middle' />

                  <Box sx={{  padding:1, mb:0.6 }} >
                <ListItem disablePadding>
                    <ListItemIcon>
                    <Box component='img' src={ creditCardLogo } sx={{ width:"2.5rem" }} />
                    </ListItemIcon>
                    <ListItemText>

                      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400, width:"10.1rem", }}>
                      Fino Balance :
                    </Typography>

                    <Typography sx={{ fontFamily:"Poppins", fontWeight:400 }}>
                      { finoProg ? <CircularProgress/> : finoBalance ? finoBalance : "****" }
                    </Typography>

                    <VisibilityIcon onClick={handleFinoBlnc} sx={{ fontSize:"1.2rem", cursor:'pointer' }} />
                    
                    {/* <Divider variant='middle' /> */}
                    </Box>
                    </ListItemText>
                </ListItem>
                  </Box> 
            </List>
            

            <List sx={{ position:"absolute", bottom:10, width:"100%" }} >
            <Box sx={{  padding:1, mb:0.6 }} >

              <Typography sx={{ fontFamily:"Poppins", fontWeight:400, fontSize:"0.8rem", color:"#949494", textAlign:'center', textTransform:'capitalize' }} >Explore wide range of brand vouchers</Typography>
               
                    <ListItemButton onClick={() => handleRedirection()} sx={{ display:'flex', alignItems:'center', justifyContent:"space-between" }} >
                    <ListItemIcon>
                    <Box component='img' src={ voucherIcon } sx={{ width:"2.5rem" }} />
                    </ListItemIcon>
                    
                    <Typography  sx={{ fontFamily:"Poppins", fontWeight:500 }}>
                      Eezib Gift Voucher
                    </Typography>
                    
                    <OpenInNewIcon sx={{ color:'#3292d1' }} />

                    </ListItemButton>

                    
                  </Box>
            <Divider variant='middle' />
            </List>



          </Box>


          </Box>
        );

        const handleQrDowload = () => {
          setTimeout(async() => {
            if (contentRef.current) {
              
              // Capture the content of the DialogContent
              const canvas = await html2canvas(contentRef.current, {
                backgroundColor: "#ffffff", // Makes background transparent if needed
                scale:3 // Adjust scale for better image quality
              });
              const dataURL = canvas.toDataURL('image/jpeg');
        
              // Create a link element to download the image
              const link = document.createElement('a');
              link.href = dataURL;
              link.download = 'eezibScanner.jpg'; // Filename for the downloaded image
              link.click();
            }
          },400);
          
        }


  return (
    <Box >
    <AppBar position='relative'sx={{ backgroundColor:'white' }} >
            <Toolbar sx={{ display:'flex', alignItems:'center', justifyContent:'space-between'}} >
               <Box onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}`) } component='img' src={eezibLogo} sx={{ width:{lg:"7rem", xs:"4rem", cursor:'pointer'  } }} />
              
                <Box onMouseLeave={ handleNameClose } sx={{ display:'flex', alignItems:'center', gap:4 }} >

                <Box onClick={ handleNameClick }  sx={{ display:'flex', alignItems:'center', justifyContent:'center', cursor:"pointer", gap:1 }} >
      {walletCount}
                        <Typography className="appBarLetters" sx={{ fontFamily:'Poppins', fontWeight:500, textTransform:'capitalize' }} >Hi {name} </Typography>
                        <ArrowDropDownIcon sx={{ color:'#363fbf' }} />
                    </Box>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorElVoucher}
                  open={openVoucher}
                  onClose={handleVoucherClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                <MenuItem sx={{ fontFamily:"montserrats", fontWeight:500 }} onClick={ () => handleRedirection() }>
                <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem" }} > Eezib Gift Voucher  </Typography>
                </MenuItem>  
              </Menu>

              <Menu
                  id="basic-menu"
                  anchorEl={anchorElName}
                  open={openLogout}
                  onClose={handleNameClose}
                  onMouseLeave={ handleNameClose }
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>

                <MenuItem onClick={ handleProfileOpen } sx={{ fontFamily:"montserrats", fontWeight:500,  display:'flex', alignItems:'center', justifyContent:'space-between', gap:1  }} >
                <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem" }} > Profile  </Typography>
                  &nbsp;
                  <PersonIcon sx={{ fontSize:'1.1rem', color:"#7a7a7a" }} />
                </MenuItem>  

                <Divider/>

                <MenuItem onClick={ () => openUpdatePass() } sx={{ fontFamily:"montserrats", fontWeight:500,  display:'flex', alignItems:'center', justifyContent:'space-between', gap:1  }} >
                <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem" }} > Update Password  </Typography>
                  &nbsp;
                  <PasswordIcon sx={{ fontSize:'1.1rem', color:"#7a7a7a" }} />
                </MenuItem> 

                <Divider/>

                <MenuItem sx={{ margin:"0px 5px 0px 5px" , padding:0, display:'flex', alignItems:'center', justifyContent:'center' }} >
                <Button size='small' onClick={() => logout()} variant='contained' color="error" sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:{xs:"0.6rem", lg:"0.8rem"  } }} endIcon={ <LogoutIcon sx={{ fontSize:{lg:"10rem", xs:"0.3rem"}, zIndex:100 }} /> } >logout</Button>

                </MenuItem>
              </Menu>
                  
                    <Tooltip TransitionComponent={Zoom}  title="Explore Menu">
                  <WidgetsIcon onClick={() => setOpenDrawer(true)}  sx={{ backgroundColor:"#6baeed", padding:1.2, borderRadius:"50%", cursor:"pointer", boxShadow:"0px 3px 8px 1px #a3a3a3" }} />
                    </Tooltip>


                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{ position: 'fixed', bottom:35, right: 16 }}
                      icon={<SpeedDialIcon />}
                      direction='up'
                    >
                      {action.map((action) => (
                        <SpeedDialAction
                        sx={{ fontFamily:"montserrat", fontWeight:500 }}
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={() => action.path ? window.open(action.path , "") : action.mail ? window.open(`mailto:${action.mail}`) : action.value ? window.location.href = `tel:${action.value}`:null }
                        />
                      ))}
                    </SpeedDial>

                </Box>

            </Toolbar>
                    <Footer />
            
        </AppBar>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={openName}
        onClose={handleClose2}
        onMouseLeave={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem  sx={{ fontFamily:'Poppins', fontWeight:450, color:'#5c5c5c' }} > Prepaid </MenuItem>
        <MenuItem   sx={{ fontFamily:'Poppins', fontWeight:450, color:'#5c5c5c' }} > Reloadable </MenuItem>
      </Menu>
{/* 
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={openName}
        onClose={handleClose2}
        onMouseLeave={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
         
        <MenuItem sx={{ fontFamily:'Poppins', fontWeight:450, color:'#5c5c5c' }} >support@eezib.com</MenuItem>
        <MenuItem sx={{ fontFamily:'Poppins', fontWeight:450, color:'#5c5c5c' }} > Terms and Condition </MenuItem>
        <MenuItem sx={{ fontFamily:'Poppins', fontWeight:450, color:'#5c5c5c' }} > नियम और शर्तें </MenuItem>

      </Menu> */}



      <Dialog
        fullScreen
        open={openProfile}
        onClose={handleProfileClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}
 >  
            <Box onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}`) } component="img" src={eezibWhite} sx={{ width:"9rem", cursor:'pointer' }}   />

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:'2rem' }} >

            <Typography sx={{  textTransform:'capitalize', fontSize:{lg:"1rem", sm:'1rem', md:"1rem", xs:"0.9rem" }, fontFamily:"montserrat", fontweight:500 }} variant="h6" component="div">
              {name} Profile
            </Typography>

            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ gap:"2rem" }}
            >
              <CloseIcon onClick={() => setOpenProfile(false)} />
            </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <DialogContent className="profilecomp" >
          <Box sx={{ display:'flex', alignItems:'center', flexDirection:{lg:'row', sm:'row', md:'row', xs:"column" } ,justifyContent:'space-evenly', marginTop:{lg:5, md:10, sm:10, xs:0}, gap:5 }} >

<Box component='img' src={profileCard} sx={{ width:{lg:"40rem", md:"38rem", sm:"35rem", xs:"25rem"} }} />
{
  profileProgress ? <CircularProgress size="10rem" thickness={1.5} /> : 

<Box sx={{ backgroundColor:'white', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" , borderRadius:3 }} >
 <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1.2rem", padding:1, mt:2 }} > Profile Details </Typography>
    <Divider variant='middle' />
 <Box sx={{  padding:3, display:'flex', alignItems:'center', justifyContent:'center', gap:2 , flexDirection:'column'}} >


    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:{lg:'row', sm:'row', md:'row', xs:"column" }, gap:1  }}  >
        <TextField  value={userName} onChange={(e) => setUserName(e.target.value)} sx={{ width:"17rem" }} InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Name">{name}</TextField>
        <TextField variant='filled' InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }}  value={phoneNumber} sx={{ width:"17rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Phone Number" />

    </Box>

    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:{lg:'row', sm:'row', md:'row', xs:"column" }, gap:1  }} >
    <TextField  InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userEmail} onChange={(e) => setUserEmail(e.target.value)}  sx={{ width:"17rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Email" />
    {/* <TextField  InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userDob} onChange={(e) => setUserDob(e.target.value)}  sx={{ width:"17rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Date Of Birth" /> */}

    <LocalizationProvider sx={{ width:"10rem" }} dateAdapter={AdapterDayjs}>
      {/* <DatePicker value={userDob} onChange={(e) => setUserDob(e) }  /> */}
      <DemoContainer components={['DatePicker']}>
      <DatePicker  disableFuture={true} defaultValue={ dayjs(userDob)} format="MM-DD-YYYY" onChange={(e) => setUserDob(moment(e?.$d).format("YYYY-MM-DD"))}  sx={{
            width: '17rem',
            '& .MuiInputBase-root': {
              fontFamily: 'montserrat', fontWeight:500,
            },
            '& .MuiFormLabel-root': {
              fontFamily: 'montserrat', fontWeight:500,
            },
            '& .MuiInputBase-input': {
              fontFamily: 'montserrat', fontWeight:500,
            },
          }} label="DOB" />
      </DemoContainer>
    </LocalizationProvider>

    </Box>

    <TextField  InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userAddress} onChange={(e) => setUserAddress(e.target.value) }  sx={{ width:{lg:"35rem", md:"32rem", sm:"25rem", xs:"18rem"} }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Address" />

    <Button onClick={ () => submitUpdate()} variant='outlined' sx={{ fontFamily:'montserrat', fontWeight:500 }} fullWidth >Submit</Button>
          {/* <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.8rem", float:'right', color:"#595959", textTransform:'underlined', cursor:"pointer" }} >Show My QR</Typography> */}
 </Box>
</Box>
}

</Box>
        </DialogContent>


      </Dialog>





      <Dialog
        open={openPassword}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlePasswordClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", 
            },
          },
        }}
      >
        <CloseIcon onClick={() => setOpenPassword(false)} sx={{ position:'absolute', right:5, top:5, cursor:'pointer' }} />
        <DialogContent className="passComp" sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly' }} >

          {/* <Box sx={{ }} >
              <Box component="img" src={passwordImage} sx={{ width:"25rem", borderRadius:10 }} />
          </Box> */}

          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2, padding:{lg:"2rem 2rem", md:"2rem 2rem", sm:"2rem 2rem", xs:"1rem 1rem"},  backgroundColor:'white', borderRadius:6, margin:{lg:"2rem 2rem", md:"2rem 2rem", sm:"2rem 2rem", xs:"0"} }} >
            
            

            <Box component="img" src={eezibLogo} sx={{ width:"7rem", marginBottom:"1rem" }} />

            <TextField label="Enter Old Password" type='password' value={oldPass} onChange={(e) => setOldPass(e.target.value)} sx={{ width:{lg:"20rem", md:"20rem", sm:"18rem", xs:"15rem" } }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 }  }} />
            <TextField label="Enter New Password" type='password' value={newPass} onChange={(e) => setNewPass(e.target.value)} sx={{ width:{lg:"20rem", md:"20rem", sm:"18rem", xs:"15rem" } }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 }  }} />
            <Button disabled={ !oldPass || !newPass } onClick={ () => updatePassword() } variant='contained' fullWidth sx={{ fontFamily:'montserrat', fontWeight:500 }} >Submit</Button>
          </Box>

        </DialogContent>
      </Dialog>


      <Dialog
        open={openQrModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleQrModel}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px", 
            },
          },
        }}
      >
        <DialogContent  ref={contentRef} sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:'column' }} >

          <Box>          
        <Box  sx={{ display:'flex', alignItems:'center', justifyContent:"center", flexDirection:'column'}} >
          <Box component="img" src={ eezibLogo } sx={{ width:"8rem" }} />
          <Typography sx={{ fontFamily:"Poppins", fontWeight:500, fontSize:'0.9rem' }} >Accepted Here</Typography>
          <Divider variant='middle' sx={{ width:"100%", mt:1 }} />
          <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem", mt:1 }} >  {sessionStorage.getItem("messageUPI")} </Typography>
       
        <div style={{ width:"14rem", cursor:'pointer' }} dangerouslySetInnerHTML={{ __html: QRModel }} />
        <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem" }} >  {sessionStorage.getItem("userEmail")} </Typography>
        <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem" }} >  {sessionStorage.getItem("userPhone")} </Typography>
        <Typography sx={{ fontFamily:'Poppins', fontWeight:400, fontSize:"0.8rem" }} >Scan & Pay</Typography>


        <Box sx={{ display:'flex', alignItems:"center", justifyContent:'space-around', width:"100%", mt:2 }} >
          <Box component='img' sx={{ width:"6rem" }} src={bhim} />
          <Box component='img' sx={{ width:"4.5rem" }} src={upi} />

        </Box>

        <Divider variant="middle" sx={{ width:"100%", mt:1 }} />

        <Box sx={{ display:"flex", alignItems:'center', justifyContent:"center", width:"100%", mt:2, mb:0, gap:1 }} >

          <Button disableRipple disableTouchRipple className='btnGrad' onClick={() => handleQrDowload()} startIcon={ <DownloadIcon/> } size='small' variant='contained' sx={{ fontFamily:'Poppins', fontWeight:400 }} >Download  Now</Button>
          {/* <Button className='btnGrad' endIcon={ <SendIcon/> } sx={{ fontFamily:'Poppins', fontWeight:400, backgroundColor:'green' }} size='small' variant='contained'  >Share QR</Button> */}
        </Box>

        </Box>     
        </Box> 

        </DialogContent>
      </Dialog>


      <Menu
        id="basic-menu"
        anchorEl={anchorElBal}
        open={openBal}
        onClose={handleBalClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{ fontFamily:'montserrat', fontWeight:500 }} onClick={handleBalClose}>
          <Box sx={{ display:'flex', alingItems:'center', justifyContent:'center' }} >
            <Typography sx={{ fontFamily:'montserrat', fontWeight:500 }} >Wallet Balance : </Typography>

            <Box sx={{ display:'flex', alingItems:'center', justifyContent:'center' }} >
            &nbsp;
              <Box component='img' src={walletLogo} sx={{ width:'1.2rem', height:"1.2rem" }} />
            <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#294bc4' }} > &nbsp; {walletBalance}</Typography>
            </Box>
          </Box>
         </MenuItem>

         
         <Divider variant='middle' />


         <MenuItem sx={{ fontFamily:'montserrat', fontWeight:500 }} onClick={handleBalClose}>
          <Box sx={{ display:'flex', alingItems:'center', justifyContent:'center' }} >
            <Typography sx={{ fontFamily:'montserrat', fontWeight:500 }} >GPR Balance : </Typography>

            <Box sx={{ display:'flex', alingItems:'center', justifyContent:'center' }} >
            &nbsp;
              <Box component='img' src={walletLogo} sx={{ width:'1.2rem', height:"1.2rem" }} />
            <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'#294bc4' }} > &nbsp; 5583 </Typography>
            </Box>
          </Box>
         </MenuItem>
      </Menu>

            {/* *************** Topup Dialogue ****************** */}


            <Dialog
            fullScreen
            open={openTopup}
            onClose={handleTopup}
            TransitionComponent={Transition}
          >
            <AppBar className='passComp' sx={{ position: 'relative' }}>
              <Toolbar sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                  <Box component="img" src={ eezibWhite } sx={{ width:"8rem" }} />
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleTopup}
                  aria-label="close"
                >
                   <CloseIcon />
                </IconButton>
                
              </Toolbar>
            </AppBar>

          </Dialog>



      <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
          {DrawerList}
      </Drawer>


        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"right" }}
          open={activateOpen}
          onClose={handleActiveCardClose}
          autoHideDuration={2500}
          >
            <Alert severity='error' >{jsonError}..</Alert>
          </Snackbar>




          <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"right" }}
          open={activateSOpen}
          onClose={handleActiveSCardClose}
          autoHideDuration={2500}
          >
            <Alert severity='success' >{jsonSError}..</Alert>
          </Snackbar>

          


        </Box>
  )
}

export default Header